import React from "react";
import { Card, Typography, Spin, Progress } from "antd";
import "../App.css";

const { Text } = Typography;

const HomePage = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Card style={{ width: "400px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin
            size="large"
            style={{ marginBottom: "20px", marginRight: "20px" }}
          />
          <Text style={{ textAlign: "center", marginBottom: "20px" }} strong>
            Under Construction
          </Text>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <Text
            type="secondary"
            style={{
              textAlign: "center",
              marginBottom: "20px",
              marginLeft: "5px",
            }}
          >
            Our website is currently undergoing scheduled Construction.
          </Text>
        </div>
        <Progress percent={20} status="active" />
      </Card>
    </div>
  );
};

export default HomePage;
